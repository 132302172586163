@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap");

.App {
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  /* background-color: #E8E4DF; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Header-Container {
  padding: 8vh 8vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
}

.Header-Name-Container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 20px;
  padding: 0 4em 1em 0;
}

.Header-Link-Container {
  width: 100px;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 20px;
}

.Header-Name {
  margin: 0;
  font-size: x-large;
  font-weight: 500;
  background-color: #04d9ff;
  transition: background-color 0.3s ease;
  line-height: 16px;
}

.Header-Name:hover {
  background-color: transparent;
}

.Header-Subname {
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-size: medium;
  color: #000000;
  transition: color 0.3s ease;
}

.Header-Subname:hover {
  color: #000;
}

.Header-Link {
  margin: 0;
  font-weight: 400;
  font-style: normal;
  font-style: italic;
  font-size: medium;
  text-decoration: none;
  color: #4a4a4a;
  transition: color 0.3s ease;
}

.Header-Link:hover {
  font-weight: 500;
  color: #000;
}

body {
  height: 100vh;
  background: linear-gradient(#e8e4df, #d6d6d6);
  overflow: hidden;
}

.noise {
  position: absolute;
  inset: 0;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/9/9a/512x512_Dissolve_Noise_Texture.png");
  background-size: 256px;
  mix-blend-mode: overlay;
  opacity: 0.5;
  pointer-events: none;
  z-index: -1;
}
